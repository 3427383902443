.footer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px;
    width: 90vw;
    max-width: 750px;
    font-family: 'Open Sans', sans-serif;
    border: black 3px solid;
    margin-bottom: 10px;
    background-color: #8b2c34;
    border-radius: 5px;
    color: black;
}

.footer p{
    text-align: center;
    margin: 2px;
}

.footer_section{
    margin-bottom: 20px !important;
}

.iconos{
    text-decoration: none;
    color: black;
}