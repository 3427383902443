.App {
    align-items: center;
    background-color: #4a4b4f;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;
      }
}

.wrapper {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.seccion{
    margin: 25px;
}

.logo{
    position: absolute;
    transform-style: preserve-3d;
    z-index: -1;
    height: 100%;
    transform: translateZ(-80px) scale(3.5);
    margin-top: 50vh;
}

@font-face {
    font-family: "The Last Shuriken";  
    src: url("../public/TheLastShuriken/TheLastShuriken-Regular.woff") format('woff'),
        url("../public/TheLastShuriken/TheLastShuriken-Regular.woff2") format('woff2'),
        url("../public/TheLastShuriken/TheLastShuriken-Regular.ttf") format('truetype'),
        url("../public/TheLastShuriken/TheLastShuriken-Regular.eot") format('eot');
  }