.card {
    height: 100% !important;
    width: 100%;

    transform-style: preserve-3d;

    position: relative;

    background-color: #9a9392;

    user-select: none;
    cursor: pointer;

    --bs-card-border-color: none !important;
}

.card-back,
.card-front{
    height: 100% !important;
    width: 100%;

    border: 2px solid black !important;
    border-radius: 5px;

    display: flex;
    
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    position: absolute;

    background-color: #9a9392;

    font-family: 'Open Sans', sans-serif;
    color: #8b2c34;
}

.card-front{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    z-index: 1px;
}

.card-back{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    transform: rotateY(180deg);
    transform: rotateX(180deg);
}

.card_title{
    justify-self: center;
    align-self: center;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2rem;
    width: 100%;
    margin: 5px 5px 0px 5px;
}

.card_price{
    justify-self: flex-end;
    align-self: center;
    font-size: 1rem;
    font-weight: 500;
    margin: 5px 2px;
}

.card_description{
    font-size: 1rem;
    line-height: 0.8rem;
    font-weight: 500;
    margin: 10px;
    line-height: 1rem;
}

.flip-exit{
    transform: rotateX(0deg);
}

.flip-exit-active{
    transform: rotateX(180deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.flip-exit-done{
    transform: rotateX(180deg);
}

.flip-enter{
    transform: rotateX(180deg);
}

.flip-enter-active{
    transform: rotateX(0deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);

}

.flip-enter-done{
    transform: rotateX(0deg);
}
