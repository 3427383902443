.accordion-button{
    background-color: #9a9392 !important;
    border: 2px black solid !important;
}
.section{   
    width: 90vw;
    max-width: 1000px;
    margin: 0px 10px 10px 10px;
}
.section_item{
    border-bottom: 1px;
    border: 1px black solid !important;
}
.section_body{
    border-left: 1px black solid !important;
    border-right: 1px black solid !important;
    border-bottom: 1px black solid !important;
}
.accordion{
--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;   
}
.accordion-button{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    color: #8b2c34 !important;   
    font-size: 1.2rem !important;
}
.accordion-button:not(.collapsed) {
    color: #8b2c34 !important;
}
.accordion-item {
    background-color: #8b2c34 !important;
}
.accordion-button {
    box-shadow: none !important;
}